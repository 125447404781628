.calc-form {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'Montserrat_Alternates_Medium', serif;
}
.calc-form__input-cont {
  position: relative;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  text-align: right;
}

.calc-form__input-title {
  margin-top: 20px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 100;
}
.calc-form__input {
  width: 100%;
  height: 46px;
  padding-left: 10px;
  border: 1px solid #afafaf;
  background: transparent;
  border-radius: 6px;
}

.calc-form__summ-container {
  margin-top: 55px;
  margin-right: -16px;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-top: 2px solid #cd850f;
}
.calc-form__summ-title {
  font-weight: 100;
  font-size: 14px;
}
.calc-form__summ {
  font-size: 31px;
  font-family: 'Inter', sans-serif;
}

.calc-form__submit-button {
  margin-top: 30px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px;
  gap: 10px;
  background: #cbab84;
  border: none;
  border-radius: 5px;
}
.calc-form__submit-button--disabled {
  margin-top: 30px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px;
  gap: 10px;

  border: none;
  border-radius: 5px;

  pointer-events: none;
  background: #f5f5f5;
  color: rgb(147, 142, 141);
}

.calc-form__submit-link {
  display: flex;
  min-width: 1000px;
}
.calc-form__submit-button:active {
  background: #946924;
}
.active {
  background: #ffffff;
}
.calc-form__submit-button:disabled {
  background: #f5f5f5;
  color: rgb(147, 142, 141);
}


.calc-form__input-error {
  position: absolute;
  right: 10px;
  top: -25px;
  color: rgb(255, 0, 0);
  font-size: 12px;
  white-space: nowrap;
}

.calc-form__input-label {
  /* margin-right: 25px; */
  margin-right: 5px;
  margin-top: -32px;
  color: #dfdfdf;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px;
  pointer-events: none;
}


@media (min-width: 600px) {
  .calc-form__input-block {
    margin-top: 20px;
    padding-left: 12%;
    display: flex;
    gap: 20px;
  }

  .calc-form__input-error {
    left: 50px;
    top: -25px;
    font-size: 13px;
  }
  .calc-form__summ-title {
    font-size: 16px;
  }
  .calc-form__summ {
    font-size: 40px;
  }
  .calc-form__input-cont {
    position: relative;
    margin-left: 0px;
  }

  .calc-form__input {
    width: 240px;
  }
  .calc-form__input-title {
    margin-left: 0px;
    font-size: 16px;
  }
  .calc-form__input-label {
    margin-right: 10px;

    font-size: 16px;
  }
  .calc-form__submit-button {
    margin-left: 12%;

    max-width: 295px;
  }
  .calc-form__summ-container {
    margin-top: 0px;
    padding-left: 12%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 0px solid #cd850f;
  }
}
