.fast-contacts-block {
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  align-items: left;
}

.fast-contacts-block__links-container--big {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  max-width: 900px;
  padding-bottom: 60px;
}
.fast-contacts-block__links-container--small {
  display: flex;
  max-width: 900px;
}
.fast-contacts__link {
  flex: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 15px; */
  padding: 4px;
  align-items: center;
  color: #453e3d;
  border-radius: 35px;
  text-decoration: none;
}

.fast-contacts__link__title {
  color: rgb(79, 77, 77);
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-weight: 500;
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  flex-wrap: nowrap;
}
.fast-contacts__icon {
  width: 50px;
  /* z-index: 35; */
}
.fast-contacts__link__title--telegram {
    color: rgb(79, 77, 77);
}
.fast-contacts__link__title--telegram:active {
  color: #0072a9
}
.fast-contacts__link__title--route {
    color: rgb(79, 77, 77);
}
.fast-contacts__link__title--route:active {
  color: #2a57a0;
}
.fast-contacts__link__title--whatsapp {
    color: rgb(79, 77, 77);
}
.fast-contacts__link__title--whatsapp:active {
  color: #15a632;
}
.fast-contacts__link__title--callus {
    color: rgb(79, 77, 77);
}
.fast-contacts__link__title--callus:active {
  color: #15a632;
}
.fast-contacts__link__title--email {

    color: rgb(79, 77, 77);
    }
.fast-contacts__link__title--email:active {
  font-family: 'Montserrat_Alternates_Medium', serif;
}

@media (min-width: 600px) {
  .fast-contacts-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 40px;
    text-align: center;
    align-items: center;
    padding-left: 0px;
  }
  .fast-contacts__link {
    display: flex;
    flex: none;
    flex-direction: row;
    width: 250px;
    flex-wrap: nowrap;
    margin: 15px;
    border-radius: 50px;
    box-shadow: 0 4px 11px 0 rgb(37 44 97 / 5%), 0 1px 3px 0 rgb(93 100 148 / 5%);
    box-sizing: border-box;
    text-decoration: none;
  }

  .fast-contacts__link__title {
    text-decoration: none;
    font-family: 'Montserrat_Alternates_Medium', serif;
    font-weight: 500;
    padding-right: 25px;
    padding-left: 5px;
    margin-top: 0px;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
  }


 
}
