.aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
}

.aboutus__text-block__container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding: 0px 50px;
}

.aboutus__text-block__container--last {
  background-image: url('src/assets/img/main/aboutUsLast.webp');
  justify-content: end;
}


.aboutus__text-block__container--one {
  background-image: url('src/assets/img/main/aboutUs1.webp');
  background-position:  right;
  text-align: right;

}

.aboutus__text-block__container--three {
  background-image: url('src/assets/img/main/aboutUs3.webp');
}

.aboutus__text-block__container--five {
  background-image: url('src/assets/img/main/aboutUs5.webp');
  justify-content: end;
  background-position: center;
}



.aboutus__text-block {
  max-width: 200px;
  font-size: 18px;
  line-height: 25px;
  color: #453e3d;
  font-family: 'Montserrat_Alternates_Medium', serif;
  padding: 20px 30px 40px 30px;
  background-color: rgba(233, 231, 227, 0.8);
  border-radius: 5px;
  margin-bottom: 150px;
}

.year-highlight {
    border-bottom: 2px solid rgb(79, 77, 77);
    color: rgb(79, 77, 77);
}

.aboutus__text-block--last {
  background-color: rgbargba(233, 231, 227, 0.8);
  text-indent: 40px;
  margin-bottom: 100px;
  
}

.line-accent {
  display: inline-block;
  border-bottom: 2px solid  #453e3d;
  padding-bottom: 2px;
  margin-bottom: 5px;
  font-family: 'Montserrat_Alternates_SemiBold', serif;
}


.aboutus__text-block--last ul {
  list-style-type: none;
  text-indent: 0px;
  padding-left: 0;
  margin: 0;
  margin-top: 30px;
}

.aboutus__text-block--last li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 8px;
  line-height: 1.6;
}

.aboutus__text-block--last li::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: rgb(79, 77, 77);
  font-size: 1.2em;
}

@media (min-width: 600px) {
  .aboutus__text-block__container {
      margin-top: 20px;
    }

  .aboutus__text-block {
    font-size: 27px;
    padding: 40px 20px 75px 40px;
    max-width: 380px;
    line-height: 1.25;
  }
    .aboutus__text-block--three {
      margin-top: 350px;
    }
    .aboutus__text-block--last {
    max-width: 550px;
    }
 .aboutus__text-block__container--three {
   justify-content: start;
 }
  


}
