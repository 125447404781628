.product {
  margin-top: 20px;
  font-family: 'Montserrat_Alternates_Regular', serif;
  background-color: transparent;
  z-index: 1000;
  font-weight: 100;
  display: flex;
  flex-direction: column;
}
.product__main-info-block {
  padding-left: 6%;
}

.product__carousel {
  padding: 20px 0px;
}
.product__presentation {
  display: flex;
  flex-direction: column;
}

.product__title {
  padding-right: 6%;
  font-size: 24px;
  font-family: 'Montserrat_Alternates_Medium', serif;
  margin: 15px 20px 0px 0px;
  padding-bottom: 10px;
}
.product__category {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #686868;
}
.product__back-link {
  padding-left: 16px;
  display: flex;
  align-items: center;
}
.product__price-disclamer {
  color: grey;
  margin-left: 15px;
  margin-top: 0px;
  font-weight: 400;
  font-size: 14px;
}
.product__back-link__title {
  font-size: 12px;
  font-weight: 500;
  padding-left: 15px;
  color: #4d4d4d;
}
.product__price-title {
  margin-left: 15px;
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
}
.product__price-block {
  margin-top: 10px;
  padding-left: 15px;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.product__price {
  /* margin-right: 10px; */
  /* justify-self: right; */

  font-size: 24px;
  font-family: 'Inter', sans-serif;
}
.product__price-for-one {
  color: #666666;
  font-size: 14px;
}
.product__specification {
  font-size: 16px;
  text-align: justify;
  hyphens: auto;
  padding-right: 6%;
  overflow: hidden;
  color: #4d4d4d;
  word-break: break-word;
}
.product__suitable-block-title {
  padding-right: 6%;
  margin-top: 20px;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 16px;
}
.product__suitable-block {
  padding-right: 6%;
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.product__suitable-product {
  margin-bottom: 2px;
  padding: 2px 20px;
  padding-bottom: 4px;
  background-color: rgba(230, 175, 86, 0.36);
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-weight: 400;
}

.product__characteristics-block {
  margin-top: 10px;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: column;
}
.product__characteristics-block__title {
  /* width: 100%; */
  margin-top: 50px;
  padding: 10px 0px;
  padding-left: 6%;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 16px;
  background: rgba(255, 244, 227, 0.52);
}
.product__characteristic {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.product__characteristic__title {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  color: #251b19;
  align-self: flex-end;
}
.product__characteristic__info {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 400;
  text-align: right;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  /* min-height: 100px; */
  width: 33%;
}

.image-gallery-thumbnail-image {
  min-height: 103px;
  object-fit: cover;
}
.image-gallery-image {
  width: 340px;
}
.image-gallery-play-button {
  display: none;
}
.product__dotted-line {
  border-bottom: 1px dashed #ebebeb;
  width: 100%;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid rgb(202, 196, 196);
}
.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid rgb(202, 196, 196);
}

@media (min-width: 600px) {
  .product__carousel {
    /* max-width: 600px; */
    width: 50%;
    justify-self: center;
  }
  .image-gallery-image {
    max-width: 1440px;
  }
  .image-gallery-thumbnail {
    /* min-height: 100px; */
    max-width: 100px;
  }

  .product__price-for-one {
    font-size: 16px;
  }
  .product__price-disclamer {
    font-size: 16px;
    margin-top: 10px;
  }
  .product__presentation {
    flex-direction: row-reverse;
    padding-left: 12%;
    padding-right: 12%;
  }
  .product__title {
    font-size: 40px;
    flex-wrap: wrap;
  }
  .product__price-title {
    font-size: 16px;
  }

  .product__price-block {
    justify-self: center;
    align-items: baseline;
  }
  .product__characteristics-block {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 12%;
    padding-right: 12%;
    justify-content: space-between;
    flex-direction: row;
  }
  .product__characteristic {
    width: 45%;
  }
  .product__volumes__container {
    max-width: 410px;
  }

  .product__characteristics-block__title {
    padding-left: 12%;
    font-size: 16px;
  }
  .product__characteristic__info {
    font-size: 16px;
    color: gray;

    justify-self: center;
  }
  .product__characteristic__title {
    font-size: 18px;
  }

  .product__price {
    font-size: 40px;
  }
  .product__specification {
    font-size: 16px;
    max-width: 70%;
  }
  .product__suitable-product {
    font-size: 16px;
  }

  .image-gallery-thumbnail {
    min-width: 140px;
    /* width: 100%; */
  }
  /* .image-gallery-content {
    display: flex;
  } */
  .image-gallery-thumbnails-wrapper.left {
    min-width: 115px;
  }

  .image-gallery-bullets {
    position: static;

    margin-top: 20px;
  }

  .image-gallery-bullets .image-gallery-bullet {
    background-color: #e4e4e4;
    border-radius: 4px;
    border: none;
    width: 8px;
    height: 8px;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
    outline: none;
    padding: 0px;
    transition: all 0.2s ease-out;
  }
  .image-gallery-fullscreen-button {
    margin-bottom: 20px;
  }
  .image-gallery-fullscreen-button:focus {
    outline: none;
  }
  .image-gallery-bullets .image-gallery-bullet.active {
    background-color: #a9792c;
    transform: none;
    border: none;
  }

  .product__back-link__title--d {
    font-size: 14px;
    font-weight: 600;

    color: #676767;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .product__arrow {
    padding-left: 10px;
    width: auto;
    margin-right: 10px;
    z-index: 50;
  }
  .product__back-link {
    padding-left: 12%;
    display: flex;
    flex-direction: row;
  }
  .product__main-info-block {
    max-width: 50%;
    padding-top: 20px;
    padding-left: 0%;
  }
}
