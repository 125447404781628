.order-form-container {
  margin-top: 60px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  overflow: hidden;
  font-family: 'Montserrat_Alternates_Medium', serif;
}

.order__title {
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 24px;
}
.order-form__input-title {
  margin-top: 15px;
}
.order__subtitle {
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #6c6b6b;
  margin-bottom: 25px;
}
.order-form__input-cont {
  position: relative;
}
.order-form__input-title {
  margin-left: 2px;

  font-weight: 400;
  font-size: 14px;
}
.order-form__input-title--err {
  color: red;
}
.order-form__input {
  margin-top: 15px;
  border: 2px solid #afafaf92;
  width: 100%;
  padding: 10px 0px 10px 0px;
  border-radius: 6px;
}
.order-form__input--err {
  border: 2px solid #dd3030c5;
}
.order-form__submit {
  margin-top: 24px;
  width: 100%;
  padding: 12px 0px 12px 0px;
  background: #cbab84;
  border: none;
  border-radius: 6px;

  color: white;

  font-size: 14px;
  font-weight: 500;
}
.order-form__submit:active {
  background: #a9792c;
}
.order-form__submit:disabled {
  background: #f5f5f5;
  color: #938e8d;
}

.order-form__error {
  position: absolute;

  font-weight: 400;
  top: 18px;
  left: 2px;
  color: red;
  font-style: italic;
  font-size: 12px;
}

.order-form__popup-cont {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 200;
  background: rgba(0, 0, 0, 0.245);
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-form__popup {
  padding: 10px;

  display: flex;
  /* width: 50%; */
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
}
.order-form__smile {
  width: 20px;
}
.order-form__popup-close {
  align-self: flex-end;
  margin-right: 14px;
  margin-top: 14px;
}
.order-form__popup-done {
  margin-top: 14px;
}
.order-form__popup__title-cont {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}
.order__warning {
  margin-top: 25px;
  font-size: 12px;
  font-weight: 400;
  margin-left: -16px;
  margin-right: -16px;
  background: #ff3d3d36;
  padding: 8px 0px 8px 0px;
  display: flex;
  justify-content: center;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
}
.order-form__popup__title-cont {
  font-family: 'Montserrat_Alternates_Medium', serif;
}
.order-form__smile {
  width: 22px;
  height: 22px;
}
.order-form__popup__subtitle {
  margin-top: 19px;
  font-size: 15px;
}
.order-form__popup-back {
  padding: 15px 25px 15px 25px;
  margin-top: 40px;
  border: none;
  border-radius: 6px;
  background: #cbab84;
  color: white;
  font-size: 14px;
}

.order-form__popup-back:active {
  background: #a9792c;
}

@media (min-width: 600px) {
  .order__title {
    font-size: 30px;
  }

  .order-form__input {
    /* margin-top: 15px;
  border: 2px solid #afafaf92;
  width: 100%; */
    padding: 15px 0px 15px 0px;
    /* border-radius: 6px; */
  }
  .order-form__submit {
    padding: 20px 0px 20px 0px;
    font-family: 'Montserrat_Alternates_Medium', serif;
    font-size: 16px;
  }
  .order-form__popup-cont {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    position: fixed;
    z-index: 200;
    background: rgba(0, 0, 0, 0.245);
    display: flex;
  }
  .order-form__popup__title-cont {
    font-size: 34px;
  }
  .order-form__popup__subtitle {
    font-size: 21px;
    color: #4d4d4d;
  }
  /* .order-form__popup {
  margin-top: 20%;
  margin-bottom: 35%;
  margin-left: 24px;
  margin-right: 24px;
  max-height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #ffffff;

} */

  .order-form__popup-back {
    padding: 20px 90px 20px 90px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Montserrat_Alternates_Medium', serif;
    font-size: 16px;
  }
}
