.cart {
  padding-top: 25px;
  top: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat_Alternates_Medium', serif;
}
.cart__header {
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__title {
  font-size: 16px;
  font-weight: 500;
}

.cart__close {
  width: 30px;
  margin-right: -15px;
}
.cart__prod-list {
  padding-bottom: 30px;
  border-bottom: 2px solid #cd850f;
}

.cart__title--big {
  padding-right: 16px;
  padding-left: 16px;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 24px;
  margin-top: 30px;
}
.cart__prod {
  margin-top: 40px;
  padding-right: 16px;
  padding-left: 16px;
  position: relative;

  display: flex;
  /* align-items: baseline; */
}
.cart__prod__title {
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 7px;
}
.cart__prod__info-block {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(205, 155, 74, 0.3);
}
.cart__prod-char {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 500;
  color: #6c6b6b;
}
.cart__warning {
  margin-top: 25px;
  font-size: 12px;
  font-weight: 400;
  background: #ff3d3d36;
  padding: 8px 0px 8px 0px;
  text-align: center;
}
.cart__warning--big {
  margin-top: 25px;
  font-size: 24px;
  font-weight: 400;
  background: #21965336;
  padding: 11px 0px 11px 0px;
  text-align: center;
}

.cart__delete {
  margin-right: -7px;
  margin-top: 5px;
  width: 30px;
}
.cart__prod-number {
  margin-top: 5px;
  background: rgba(230, 175, 86, 0.34);
  max-width: 26px;
  width: 100%;
  height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  border-radius: 20px;
}
.cart__total-goods {
  margin-top: 15px;
  font-size: 18px;
  color: #6c6b6b;
}
.cart__order-block {
  display: flex;
  flex-direction: column;
}

.cart__total-char {
  margin-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
}
.cart__total-char-text {
  font-size: 14px;
}

.cart__summ-block {
  margin-top: 40px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart__summ-title {
  color: #ababab;
  font-size: 16px;
  font-family: 'Montserrat_Alternates_Medium', serif;
}

.cart__summ {
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 32px;
}
.cart__prod--blur {
  filter: blur(4px);
}

.cart__warning {


}

.popup-container {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 200;
  background: rgba(0, 0, 0, 0.587);
  font-family: 'Open_Sans_R', serif;
}

.popup {
  max-height: 545px;
  max-width: 767px;
  background: #ffffff;
  border-radius: 10px;
}

.popup__header {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px 10px 35px;
  border-bottom: 1px solid #ebebeb;
}

.popup__title {
  font-weight: 400;
  font-size: 21px;
}

.popup__close-button {
  background: transparent;
  border: none;
}

.popup__product-photo {
  width: 100px;
  height: 125px;
  object-fit: cover;
}

.popup__product-photo--cart {
  max-width: 200px;
  height: 250px;
  object-fit: cover;
}

.popup__product-cont {
  display: flex;
  position: relative;
  /* justify-content: space-between; */
  /* width: 100%; */
}

.popup__product {
  margin-top: 20px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  margin-left: 35px;
  margin-right: 20px;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  /* width: 95%; */
}

.popup__product--cart {
  margin-top: 25px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  width: 100%;
  display: flex;

  padding-bottom: 0px;
  border-bottom: 0px solid #ebebeb;
}

.popup__product--blur {
  filter: blur(4px);
}

.popup__deleted {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  padding: 7px 0px 7px 0px;
  max-width: 170px;
  background: rgba(235, 87, 87, 0.75);
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  color: white;
  z-index: 5;
}

.popup__product__info-block {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.popup__product-title {
  font-size: 16px;
  font-weight: 500;
  max-width: 250px;
  margin-bottom: 20px;
}

.popup__product-char {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}

.popup__footer {
  border-top: 1px solid #ebebeb;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
}

.popup__product__price-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-self: flex-end; */
  margin-left: auto;
}

.popup__product-price {
  display: flex;
  justify-content: right;
  font-size: 18px;
  font-family: 'Open_Sans_B', sans-serif;
  white-space: nowrap;
}

.popup__delete {
  cursor: pointer;

  margin-bottom: 20px;
  background: transparent;
  border: none;
  font-size: 14px;
  background: rgba(235, 87, 87, 0.667);
  padding: 3px;
  border-radius: 10px;
}

.popup__footer {
  height: 135px;
  padding-left: 35px;
  padding-right: 35px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.popup__continue {
  /* background: #f3f4f3; */
  background: rgb(243, 244, 243);
  border: 1px solid #e8e8e8;
  padding: 20px;
  border-radius: 6px;
  font-size: 14px;
  color: #251b19;
}

.popup__continue:active {
  background: rgb(249, 249, 249);

  color: #251b196e;
}

.popup__order-button-container {
  background: #ecf5ed;
  padding: 20px;
  border: 1px solid #469e53;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 25px;
}

.popup__total {
  font-family: 'Open_Sans_B', serif;
  font-size: 38px;
}

.popup__order-button {
  width: 182px;
  height: 55px;
  background: #469e53;
  border: 1px solid #469e53;
  color: white;
  border-radius: 5px;
  font-family: 'Open_Sans_B', serif;

  font-size: 14px;
}

.popup__order-button:active {
  background: #347d3f;
}

.popup__product-photo--cart {
display: none;
}
@media (min-width: 600px) {
  .cart__header {
    padding-left: 12%;
    display: flex;
    justify-content: flex-start;
  }

  .cart__total-char-text {
    font-size: 16px;
  }
  .popup__product--cart {
    position: relative;
  }
  .popup__product--cart--blur {
    filter: blur(4px);
  }

  .cart__horizontal-cont {
    padding-right: 12%;
    padding-left: 12%;
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  .cart__title--big {
    padding-left: 0px;

    font-size: 30px;
  }
  .cart__prod-list {
    border-bottom: none;
  }

  .cart__total-char {
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
  }
  .cart__total-block {
    margin-top: 35px;
    padding: 10px 30px 30px 30px;
    border: 1px solid #ebebea;
  }

  .cart__summ-block {
    margin-top: 20px;
    padding: 12px 0px 12px 0px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
  }
  .cart__summ {
    font-size: 21px;
  }
  .cart__summ-title {
    font-size: 18px;
    color: black;
  }
  .order-form-container {
    border: 1px solid #ebebea;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 40px 33px 50px 33px;
  }
  .cart__order-block {
    max-width: 400px;
  }
}


@media (min-width: 800px) {

  .popup__product-photo--cart {
    display: block;
      width: 200px;
      height: 250px;
      object-fit: cover;
    }

}