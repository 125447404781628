.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-size:contain;
  background-attachment: scroll;
  background-repeat: no-repeat;

}
.layout--without_back {
  background: none;
}


:root {
  --content-max-width: 1440px;
}

.header_logo {

  padding-top: 10px;
  height: 90px;
  filter: brightness(93%) saturate(91%) invert(92%) sepia(0%) saturate(95%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.layout__header__svg svg {
  color: #ede9e9;
  width: 25px;
  height: auto;
}



.layout__header {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, #bb9286ba, #ada898d5);
  width: 100%;
  z-index: 1000;
  
}

.homebutton-container {
    margin-left: 5%;
}
.homebutton {
    color: #ede9e9;
}

.layout__links-container {
  /* display: none; */
}

.layout__link {
  /* margin-left: 40%; */
  display: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  height: 38px;
  padding: 4px 12px;
  border-radius: 50px;
  box-shadow: 0 4px 11px 0 rgb(37 44 97 / 5%), 0 1px 3px 0 rgb(93 100 148 / 5%);
  transition: all .2s ease-out;

 
  text-align: center;
  align-items: center;
}
.layout__link--cart {
  position: relative;
  display: flex;
  /* margin-left: auto; */
  margin-top: 10px;
  margin-right: 5%;
  justify-self: flex-end;
}

.layout__link__title {
  font-size: 13px;
  color: #ede9e9;
  font-family: 'Montserrat_Alternates_Medium', serif;
  text-align: center;
}


.layout__cart-icon {
  fill: #ebe9e9;
  width: 50px;
  height: 50px;
  opacity: 0.9;
}

.layout__cart-indicator {
  display: flex;
  justify-content: center;

  align-items: center;
  background-color: #ab987997;
  color: #ede9e9;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 12px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  height: 12px;
  width: 12px;
  margin-left: -11px;
  margin-top: -10px;
}
.show{
  display: flex;
}

@media (min-width: 600px) {
  .layout {
    max-width: 1460px;

  }

.layout__link--single {
  display: flex;
  margin-left: auto;
  margin-right: 10%;
}

  .layout__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

  }



  .layout__link {
    display: flex;
  }
  .layout__links-container {
    display: flex;
    flex-direction: row;
  }
  .layout__link--cart {
    margin-left: 20px;
    margin-right: 10%;
  }
  .layout__cart-icon {
    width: 40px;
    background-color: transparent;
  }
  .layout__button__icon {
    width: 30px;
  }
  .layout__cart-indicator {
    height: 13px;
    width: 13px;
    margin-left: -20px;
  }
}
