.ourstory-screen {
  padding: 70px 20px 100px 20px;
  background-image: url('src/assets/img/main/ourStory.webp');
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  background-attachment: fixed; 
}

.ourstory-screen__text__container {
  padding: 20px 15px 40px 15px;
  background-color: rgba(233, 231, 227, 0.8);
  height: auto;
  border-radius: 5px;
}

.ourstory-screen__title {
  font-size: 24px;
  color: #453e3d;
  font-family: 'Montserrat_Alternates_SemiBold', serif;
  text-align: center;
}

.ourstory-screen__subtitle {
  margin: 40px 0 20px 0;
  font-size: 16px;
  color: #453e3d;
  font-family: 'Montserrat_Alternates_SemiBold', serif;
  text-align: center;
}

.ourstory-screen__text-block {
  margin-top: 20px;
  font-size: 14px;
  line-height: 25px;
  color: #453e3d;
  text-align: justify;
  hyphens: auto;
  font-family: 'Montserrat_Alternates_Medium', serif;
  text-indent: 15px;
}




@media (min-width: 600px) {
  .ourstory-screen {
      padding: 70px 200px 200px 200px;
      background-attachment: scroll;
    }
  .ourstory-screen__text__container {
      padding: 50px 50px 50px 50px;
    }

  .ourstory-screen__text-block {
      margin-top: 30px;
      font-size: 25px;
      line-height: 30px;
      text-indent: 35px;
  }

 .ourstory-screen__title {
   font-size: 42px;
   text-align: center;
 }

  .ourstory-screen__subtitle {
  margin: 50px 0px 0px 0px;
  font-size: 30px;
  }
}