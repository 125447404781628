.category-list__title {
  margin-top: 40px;
  text-align: center;
  font-family: 'Montserrat_Alternates_SemiBold', serif;
  margin-bottom: 50px;
  font-size: 25px;
  color:rgb(71, 71, 71);
}




.category-list__warning {
  font-family: 'Montserrat-Italic';
  width: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
  color: gray;
  font-size: 17px;
  border-bottom: 1.5px solid rgba(225, 39, 39, 0.888);
  padding-bottom: 3px;
  margin-bottom: 20px;
}

.category-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.category-list__tire {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
@media (min-width: 600px) {

  .category-list__title {
      font-size: 45px;
    }

}
