.google-map__container {
  width: 100%;
}
.google-map__title {
  text-align: center;
  align-items: center;
  font-family: 'Montserrat_Alternates_Medium', serif;

  font-size: 26px;
  color: rgb(80, 79, 79);
  margin: 20px;
  fill: black;
}

.google-map {
  margin: 5px;
  padding: 5px;
  height: 100vh;

  width: 98%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.google-map__adress {
  position: relative;
  display: flex;
  font-family: 'Montserrat_Alternates_Regular';
  justify-content: center;
}
.adress__title {
  align-self: center;
  font-weight: 800;
  font-size: 18px;
  white-space: nowrap;
}

.adress__link {
  position: relative;
  display: inline-block;
  font-size: 2em;
  font-weight: 800;
  color: royalblue;
  overflow: hidden;
}

.adress__icon {
  width: 30px;
}
.adress__info {
  text-decoration: none;
  padding-left: 10px;

  align-self: center;
  padding-right: 10px;

  white-space: nowrap;

  font-size: 14px;
}

.route-button__title {
  /* padding: 8px; */
  font-size: 15px;
  /* white-space: nowrap; */
  color: white;
  font-family: 'Montserrat_Alternates_Medium', serif;
}
.route-button__icon {
  width: 15px;
}
.google-map__route-button {
  margin-top: 10px;
  margin-bottom: 38px;

  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid rgb(251, 238, 238);

  background-color: rgb(47, 103, 223);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.google-map__route-button:active {
  position: relative;
  top: 1px;
  text-shadow: none;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

/* .google-map__route-button:hover {
  background-color: rgb(87, 132, 229);
  color: #555;
} */
.google-map__route-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.google-map__route-button--map {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 290px;
  height: 50px;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: start;
  cursor: pointer;
  margin-left: -15px;
  align-self: center;
  background-color: rgb(47, 103, 223);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.google-map__route-button--map:active {
  margin-top: -90px;
  margin-right: -100px;
  position: absolute;
  right: 170%;

  top: 1px;
  text-shadow: none;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

/* .google-map__route-button--map:hover {
  background-color: rgb(87, 132, 229);
  color: #555;
} */

.google-map__route-button--map::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media (min-width: 600px) {
  .adress__title {
    font-size: 20px;
  }
  .adress__info {
    font-size: 18px;
  }

  .google-map__route-button {
    margin-left: 30%;
  }
}
