.contacts__container {
  margin-top: 5px;
  background-repeat: no-repeat;
  font-family: 'Montserrat_Alternates_Medium', serif;
  display: flex;
  position: relative;
  padding: 10px 0px 0px 0px;
  overflow: hidden;
  background-color: #e9e7e2;
}

.contacts__contact-block {
  background-color: white;
  padding-left: 10%;
  display: flex;
  align-self: flex-end;
  justify-self: right;
  flex-direction: column;
  max-width: 600px;
  background-color: transparent;

  overflow: hidden;
}
.contacts__list {
  display: flex;
  flex-direction: column;
}

.contact__icon {
  width: 30px;
  align-self: center;
  position: absolute;
  opacity: 80%;
  filter: grayscale(100%);
}
.contacts__pointer {
  margin-right: -5px;
  margin-top: -10px;
}
.contacts__contact-block__title {
  padding-top: 30px;
  z-index: 2;
  align-items: center;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 24px;
  font-weight: 700;
  color: rgb(80, 79, 79);
}

.contacts__contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.contact__title {
  font-family: 'Montserrat_Alternates_Medium', serif;
  margin-bottom: 10px;
  font-size: 18px;
  color: rgb(80, 79, 79);
}

.contact__link {
  position: relative;
  display: inline-block;
}

.contact__info {
  font-size: 16px;
  color: rgb(80, 79, 79);
}
.contact__info:hover {
  color: #005bff;
}
.contacts__spike {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.contacts__spike path {
  fill: rgb(236, 220, 197);
}

@media (min-width: 600px) {
  .contacts__contact-block {
    padding: 0px 80px 20px 40px;
    background-color: rgba(233, 231, 227, 0.8);
  }
  .contacts__contact-block__title {
    padding-top: 30px;
  }

  .contacts__container {
    background-color: white;
    justify-content: flex-end;
    background-image: url('src/assets/img/main/contacts_back.jpg');
    padding: 150px 0px 150px 0px;
    margin-top: 20px;
  }

  .contacts__list {
  flex-direction: row;
  flex-wrap: wrap;
  }

  .contacts__contact {
    min-width: 50%;
  }
}
