.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('src/assets/img/main/landing.webp');
  background-size: cover;
  position: relative;
  height: calc(100vh - 80px);
}

.landing__text-block {
  color: #453e3d;
  font-size: 18px;
  line-height: 1.3;
  font-family: 'Montserrat_Alternates_Medium', serif;
  padding: 10px 15px 20px 15px;
  max-width: 230px;
  margin-top: 38%;
  background-color: rgba(233, 231, 227, 0.8);
  border-radius: 5px;
  text-indent: 15px;

}
.landing__text-block--highlighted {
  font-family: 'Montserrat_Alternates_SemiBold', serif;
  color: #453e3d;
}


/* 
@media (min-width:600px) {
.landing__text-block {
  max-width: 350px;
  margin-top: 38%;
  padding: 20px 30px 40px 30px;
  font-size: 26px;
  line-height: 1.4;
}


} */


@media (min-width:800px) {
  .landing__text-block {
    max-width: 450px;
    margin-top: 18%;
    padding: 20px 20px 40px 60px;
    font-size: 26px;
    line-height: 1.4;
  }
  
  .landing{
  background-position: center;
  height: 120vh;
}
}

