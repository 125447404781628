.fastcontact {
  display: flex;
  flex-direction: row;
  gap: 25px;
  z-index: 1000;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding-bottom: 3%;
}

.fastcontact__item {
  padding: 0;
  margin: 0;
  width: 70px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fastcontact__icon {
  width: 100%;
  height: 100%;
  display: block;
}

.fastcontact__icon:active {
  filter: none;
}

@media (min-width: 800px) {
  .fastcontact {
      gap: 30px;
      bottom: 60px;
      right: 120px;
    }
  .fastcontact__item {
    width: 80px;
    height: 80px;
  }
}