body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoing: grayscale;
}
a {
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
}
input:focus,
textarea:focus {
  outline: none;
}
.app {
  display: flex;

  flex-direction: column;
}
.bold-text {
  font-weight: bold;
}
p,
h1 {
  color: #000;
}
.hide {
  display: none !important;
}
@import '~react-image-gallery/styles/css/image-gallery.css';


@font-face {
  font-family: 'Montserrat_Alternates_Black';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_BlackItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Bold';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_BoldItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_ExtraBold';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_ExtraBoldItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_ExtraLight';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_ExtraLightItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Italic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Light';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_LightItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Medium';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_MediumItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Regular';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_SemiBold';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_SemiBoldItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_Thin';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat_Alternates_ThinItalic';
  src: url('src/assets/fonts/Montserrat/MontserratAlternates-ThinItalic.ttf') format('truetype');
}


/* @media (prefers-color-scheme: dark) {
  body,
  a {
    background: #000;
    color: #ffff;
  }
}

@media (prefers-color-scheme: light) {
  body,
  a {
    background: #ffff;
    color: #000;
  }
}  */

.common-button {
  white-space: nowrap;
  height: 30px;
  padding: 5px;
  font-family: 'Montserrat_Alternates_Medium', serif;

  display: flex;
  font-size: 0.7rem;
  cursor: pointer;
  border: 1px solid rgb(251, 238, 238);
  color: #555;
  background-color: #ffff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 0))
  );

  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -ms-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  background-clip: padding-box; /* Fix bleeding */
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0 1px 0 rgba(243, 209, 209, 0.3),
    0 2px 2px -1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.3) inset;
  -webkit-box-shadow: 0 1px 0 rgba(243, 209, 209, 0.3),
    0 2px 2px -1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.3) inset;
  box-shadow: 0 1px 0 rgba(243, 209, 209, 0.3),
    0 2px 2px -1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.3) inset;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.common-button:hover {
  background-color: #eee;
  color: #555;
}
.common-button:active {
  background: #e9e9e9;
  position: relative;
  top: 1px;
  text-shadow: none;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

.common-button__title {
}
.common-button__icon {
  max-width: 30px;
}
