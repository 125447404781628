.snackbar {
  visibility: hidden;
  width: 300px;
  margin-left: -125px;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 16;
  left: 25%;
  bottom: 30px;
  font-size: 17px;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.snackbar--success {
  background-color: #5bd75b;
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.3s;
  animation: fadein 0.5s, fadeout 0.5s 2.3s;
}
.snackbar--error {
  background-color: #d75b5b;
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.3s;
  animation: fadein 0.5s, fadeout 0.5s 2.3s;
}

@-webkit-keyframes fadein {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 25%;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 25%;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    left: 25%;
    opacity: 1;
  }
  to {
    left: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    left: 25%;
    opacity: 1;
  }
  to {
    left: 0;
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .snackbar {
    bottom: 75px;
    left: 35%;
    width: 300px;
  }

  @keyframes fadein {
    from {
      left: 0;
      opacity: 0;
    }
    to {
      left: 35%;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      left: 0;
      opacity: 0;
    }
    to {
      left: 35%;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      left: 35%;
      opacity: 1;
    }
    to {
      left: 0;
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      left: 35%;
      opacity: 1;
    }
    to {
      left: 0;
      opacity: 0;
    }
  }
}
