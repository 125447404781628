.product__volumes-form {
  margin: 10px 16px 0px 16px;
  font-family: 'Montserrat_Alternates_Medium', serif;
}

.product__volumes-form__title {
  color: #4d4d4d;
}
.product__volumes__container {
  margin-top: 10px;
  display: flex;
  height: 50px;
  width: 100%;
  flex-wrap: nowrap;
}
.product__volume {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px; */
  width: 100%;
  height: 100%;
}
/* .product__volume--first {
  border-radius: 15% 0% 0% 15%;
}
.product__volume--last {
  border-radius: 0% 15% 15% 0%;
  border-right: none;
} */

.product__volume__radio {
  -webkit-appearance: none;
  border: none;
  position: absolute;
}
.product__volumes-block__title {
  margin-top: 15px;
  font-weight: 400;
}

.product__volume__radio-label-text {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: #251b19;
  font-weight: 700;
  font-size: 19px;
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  align-items: center;
}

.product__volume__radio-label {
  width: 100%;
  height: 100%;
  border-right: 1px solid #ffe8c2;

  background: #ffe8c266;

  cursor: pointer;
}
.product__volume__radio-label--first {
  border-radius: 10px 0px 0px 10px;
}
.product__volume__radio-label--last {
  border-radius: 0px 10px 10px 0px;
  border-right: none;
}
.product__volume__radio-label--sole {
  border-radius: 10px;
}

.product__volumes-form input:checked + label {
  /* background-color: #bfb; */
  background: #ffe8c2;
  border: 1px solid #f6ddb3;
}

.product__type-of-bag {
  margin-top: 5px;
  font-size: 14px;
  color: #4d4d4d;
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-weight: 400;
}
@media (min-width: 600px) {
  .product__type-of-bag {
    font-size: 18px;
  }

  .product__volumes-form__title {
    font-size: 17px;
  }
  .product__volumes-block__title {
    font-size: 16px;
  }

  .product__volumes-form {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 12%;
    width: 30%;
    justify-self: center;
  }

  .product__volume__radio-label {
    font-size: 20px;
  }

  .product__volume__radio-label-text {
    width: 100%;
    height: 100%;
    justify-content: center;
    color: #251b19;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
    align-items: center;
  }

  .product__volume__radio-label {
    width: 100%;
    height: 100%;
    border-right: 1px solid #ffe8c2;

    background: #ffe8c266;

    cursor: pointer;
  }
}
