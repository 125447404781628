.product-preview__list{
  display: flex;
  flex-direction: column;
}


.product-preview__container{
    display: flex;
    scroll-behavior: smooth;
    padding: 20px;
  }

.product-preview {
 width: 100%;
}

.product-preview__icon {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  margin-bottom: -20px;
  height:  300px;
  object-fit: cover;
}
.product-preview__info-block {
  padding: 25px 10px 10px 10px;
  font-family: 'Montserrat_Alternates_Regular', serif;
  display: flex;
  justify-content: space-between;
  height: 75px;
  color: rgb(71, 71, 71);
  font-size: 16px;
  border: 3px solid #eeeeee;
  border-top: 0px;
  border-radius: 0px 0px 16px 16px;
}
.product-preview__info-block__left-section {
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 10px;
}
.product-preview__title {
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px; 
  margin-left: 4px;
}

.product-preview__short-category {
  padding: 2px 5px;
  border: 1px solid #c891394d;
  border-radius: 5px;
  color: black;
  font-size: 13px;
  white-space: nowrap;
}
.product-preview__title:hover {
  color: #005bff;
}

.product-preview__info-block__right-section {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  text-align: flex-end;
  align-items: column;

  gap: 10px;
  flex-direction: column;
}  

.product-preview__price-block__price-title{
  font-family: 'Montserrat_Alternates_Light', serif;
  font-size: 12px;
}

.product-preview__price-block__price{
  font-family: 'Montserrat_Alternates_Medium', serif;
  font-size: 14px;
  
}
.product-preview__price-block__ruble-symbol{
  font-size: 14px;
  margin-left: -6px;
}


.product-preview__price-block {
  display: flex;
  font-size: 16px;
  align-items: baseline;
  white-space: nowrap;
  justify-content: end;
}

.product-preview__price-block__sale-slogan {
  font-family: 'Montserrat_Alternates_Italic', serif;
  font-size: 13px;
  text-align: center;
}

@media (min-width: 600px) {
  .product-preview__list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  .product-preview__title {
    font-size: 20px;
  }
  .product-preview {
    width: 340px;
  }
  .product-preview__icon {
    height: 250px;
  }
  .product-preview__info-block {
    height: 100px;
  }
}
